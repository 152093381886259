.customRadio {
  display: none;

  &+label {
    background: #c1c1c1cf;
    border: 1px solid #9999994d;
    padding: 10px 18px;
    border-radius: 6px;
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 13px;
    color: #fff;
    height: 46px;

    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &:checked+Label {
    background: #196bac;
  }

}