$small: 450px;
$medium: 992px;
$large:1366px;

@media screen and (max-width: $medium) {
    .sidebar {
        left: -240px;

        &.active{
            left: 0;
        }
    }

    .main {
        width: 100%;
        left: 0;
    }

    .user {
        img {
            height: 45px;
            width: 45px;
        }

        .name {
            display: none;
        }

        button::after {
            margin-top: 21px !important;
        }
    }

    .xs-child-gap-large>div:not(:last-child) {
        margin-bottom: 30px;
    }

    .xs-child-gap>div:not(:last-child) {
        margin-bottom: 10px;
    }

    .project-card {
        margin-left: 0 !important;

        >div:first-child {
            text-align: center;
        }

        .actions>div {
            margin-bottom: 10px;
        }
    }

    .farmerDetail__tbody td:nth-child(2) {
        min-width: 150px
    }

    .meter-details .graph .refresh-btn {
    // margin-top: 30px;
    // margin-right: auto;
}

.meter-details .graph .header {
    padding: 16px 10px;
}
}


@media screen and (max-width: $small) {
    .main {
        padding: 0 10px;
    }

    .main-content {
        margin: 0;
    }

    .logo {
        transform: none !important;
        max-width: 210px;
    }

    .go-back button {
        font-size: 10px;
    }

    .page-title h2 {
        font-size: 16px;
    }

    .page-header {
        margin-bottom: 20px;
    }

}



