.filePicker {
  border: 2px solid #0074d9;
  padding: 10px 15px;
  border-radius: 7px;
  display: inline-block;

  .filePickerLabel {
    font-size: 15px;
    font-weight: 500;
    color: #0074d9 !important;

    &:hover {
      cursor: pointer;
    }
  }

  svg {
    margin-right: 9px;
    font-size: 40px;
    color: #0074d9;
  }
}

.filePickerTwo {
  border: 2px dashed silver;
  padding: 10px 15px;
  border-radius: 7px;
  display: inline-block;

  .filePickerLabelTwo {
    font-size: 15px;
    font-weight: 500;
    color: grey !important;

    &:hover {
      cursor: pointer;
    }
  }
}