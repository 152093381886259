  .root {
      min-height: 75vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);

  @media screen and (min-width: 1200px) {
          padding-right: 12rem;
  }
  }

  .spinner {
      height: 80px;
      width: 80px;
  }

