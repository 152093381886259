$primary-color: #0074d9;
$primary-color-dark: #4173bc;
$secondary-color: #ff0000;
$white: #ffffff;
$light-black: #333333;
$active-color: #0059ff;
$border-grey: #cccccc;
$light-grey: #adadad;
$lighter-grey: #c1c1c133;
$input-bg-grey: #eeeced;
$light-blue: #3081f733;
$black: rgba(49, 48, 51, 1);
$text-grey: rgba(120, 117, 121, 1);
