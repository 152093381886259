  .root {
      position: fixed;
      z-index: 1111;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);
  }

  .spinner {
      height: 80px;
      width: 80px;
  }
