.root {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    inset: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alertBox {
    width: 400px;
    background: #eeeeee;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    animation: grow 0.5s ease;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.alertCross {
    width: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    opacity: 0.6;
}

@keyframes grow {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}